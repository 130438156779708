<template>
  <a-modal
    :title="title"
    width="800px"
    :visible="visible"
    transitionName=""
    maskTransitionName=""
    :destroyOnClose="true"
    :confirmLoading="loading"
    centered
    @cancel="handleCancel"
  >
    <a-tree
      v-if="!loading"
      checkable
      checkStrictly="true"
      :tree-data="treeData"
      v-model:expandedKeys="expandedKeys"
      v-model:selectedKeys="selectedKeys"
      v-model:checkedKeys="checkedKeys"
      v-model:replaceFields="replaceFields"
    ></a-tree>
    <template #footer>
      <a-button key="submit" type="primary" @click="handleSubmit">保存</a-button>
      <a-button key="back" @click="handleCancel">取消</a-button>
    </template>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

import { query_menu_list } from '@/api/permission/menu-manage';
import { queryApiurl } from '@/api/permission/apiurl-manage';
import { updateRole } from '@/api/permission/role-manage';

import { TreeDataItem } from 'ant-design-vue/es/tree/Tree';

import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import bus from '@/utils/bus';

export default defineComponent({
  name: 'AuthEditModal',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    role_id: {
      type: Number,
      required: true,
    },
    aim_org_id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: '修改菜单权限',
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const bus_obj = bus;
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const loading = ref(false);
    const expandedKeys = ref<string[]>([]);
    const selectedKeys = ref<string[]>([]);
    const checkedKeys = ref<any>({ checked: [], halfChecked: [] });
    const replaceFields = { children: 'children', title: 'name', key: 'key' };
    const treeData: TreeDataItem = ref([]);
    let menu_dict: any = {};
    let url_dict: any = {};
    const state = ref({
      all_menu_list: [],
    });
    watch(expandedKeys, () => {
      console.log('expandedKeys', expandedKeys);
    });
    watch(selectedKeys, () => {
      console.log('selectedKeys', selectedKeys);
    });
    watch(checkedKeys, () => {
      console.log('checkedKeys', checkedKeys);
      console.log('treeData', treeData);
    });
    const uuid = () => {
      let s: any[] = [];
      let hexDigits = '0123456789abcdef';
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = '-';

      return s.join('');
    };
    const get_tree_menu = (menu_all_list: any[]) => {
      let return_menu: any[] = [];
      let parent_menu_dict: any = {};
      menu_all_list.map((item: any) => {
        item.key = 'menu_id,' + item.id + ',' + uuid();
        menu_dict[item.id] = item.key;
        if (!item.parent) {
          return_menu.push(item);
        } else {
          let f = parent_menu_dict[item.parent.id];
          item.children = item.url_id_list.map((obj: any) => {
            obj.key = 'url_id,' + obj.id + ',' + uuid();
            url_dict[obj.id] = obj.key;
            return obj;
          });
          if (!f) {
            parent_menu_dict[item.parent.id] = [item];
          } else {
            parent_menu_dict[item.parent.id].push(item);
          }
        }
      });
      return_menu.map((top_parent: any) => {
        if (parent_menu_dict[top_parent.id]) {
          top_parent.children = parent_menu_dict[top_parent.id];
          top_parent.children.map((item: any) => {
            if (parent_menu_dict[item.id]) {
              item.children = parent_menu_dict[item.id];
              item.children = item.children.sort((a: any, b: any) => a.index - b.index);
            }
          });
        } else {
          top_parent.children = top_parent.url_id_list.map((obj: any) => {
            obj.key = 'url_id,' + obj.id + ',' + uuid();
            url_dict[obj.id] = obj.key;
            return obj;
          });
        }
        top_parent.children = top_parent.children.sort((a: any, b: any) => a.index - b.index);
      });
      return return_menu;
    };
    const handleCancel = () => {
      emit('cancel');
    };
    const handleSubmit = () => {
      console.log(selectedKeys);
      console.log(checkedKeys);
      let url_ids: any[] = [];
      let menu_ids: any[] = [];
      checkedKeys.value.checked.map((item: any) => {
        if(item){
          let temp_list = item.split(',');
          if (temp_list[0] === 'url_id') {
            url_ids.push(temp_list[1]);
          }
          if (temp_list[0] === 'menu_id') {
            menu_ids.push(temp_list[1]);
          }
        }
      });
      updateRole({
        org_id: current_org?.id,
        role_id: props.role_id,
        url_ids: [...new Set(url_ids)].join(','),
        menu_ids: [...new Set(menu_ids)].join(','),
      }).then(() => {
        emit('ok');
        bus_obj.emit('refresh_auth_manage');
      });
    };
    const init = () => {
      loading.value = true;
      query_menu_list({
        aim_org_id: props.aim_org_id,
        org_id: current_org?.id,
        pageSize: 2000,
        is_active: true,
      })
        .then(res => {
          treeData.value = get_tree_menu(res.data);
          console.log(treeData);
        })
        .then(() => {
          query_menu_list({
            aim_org_id: props.aim_org_id,
            org_id: current_org?.id,
            role_id: props.role_id,
            is_active: true,
            pageSize: 2000,
          })
            .then(res => {
              // res.data.map((item: any) => {
              //   selectedKeys.value.push(menu_dict[item.id]);
              // });
              res.data.map((item: any) => {
                checkedKeys.value.checked.push(menu_dict[item.id]);
              });
              // console.log(selectedKeys);
              // console.log(checkedKeys);
            })
            .then(() => {
              queryApiurl({
                aim_org_id: props.aim_org_id,
                org_id: current_org?.id,
                role_id: props.role_id,
                is_active: true,
                pageSize: 2000,
              }).then(res => {
                //获取该角色下的url
                // res.data.map((item: any) => {
                //
                //   selectedKeys.value.push(url_dict[item.id]);
                // });
                res.data.map((item: any) => {
                  checkedKeys.value.checked.push(url_dict[item.id]);
                });
                // console.log(selectedKeys);
                // console.log(checkedKeys);
                loading.value = false;
              });
            });
        });
    };
    init();

    return {
      props,
      state,
      loading,

      treeData,
      expandedKeys,
      selectedKeys,
      checkedKeys,
      replaceFields,

      handleSubmit,
      handleCancel,
    };
  },
});
</script>
